import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import ImageHeroBanner from "../components/ImageHeroBanner/ImageHeroBanner"
import BreadCrumb from "../components/BreadCrumb/BreadCrumb"
import { getUrlValues } from "../helpers/search"
import ProductSearch from "../components/ProductSearch/ProductSearch"
import { pageSlugs } from "../constants/pageType.constants"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head";

const SearchPage = ({ pageContext }) => {
  const { globalComponents, componentProps, seoData, pageType, dataLayer, apiData } =
    pageContext

  const filter = getUrlValues({})
  const { search } = filter || {}
  const [searchTerm, setSearchTerm] = useState(search)
  
  const componentData = (componentProps || []).reduce((a, b) => {
    if (b && Object.keys(b)[0]) {
      a[Object.keys(b)[0]] = Object.values(b)[0]
    }
    return a
  }, {})

  const { items: navigationCollectionItems } = componentData?.BreadCrumb?.navigationCollection
    || { items: [] }
  const breadCrumbItems = {
    items: [
      ...(navigationCollectionItems).map(m => {
        if (m?.link?.url?.indexOf(`${pageSlugs.SEARCH_PAGE}`)) {
          m.link.url = `${pageSlugs.SEARCH_PAGE}/?search=${searchTerm}`
        }
        return m
      })
    ]
  }

  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: pageType || "others",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }

  const onSearchTermChange = (term) => {
    setSearchTerm(term)
  }

  const bannerData = { ...(componentData?.ImageHeroBanner || {}) }

  return (
    <Layout seoData={seoData} globalComponents={globalComponents} pageType={pageType}>
      <ImageHeroBanner
        {...bannerData}
        heading={`${bannerData?.heading} ${searchTerm ? '"' + searchTerm + '"' : ""}`}
        variant={'SEARCH_PAGE'}
      />
      <div
        className="flex flex-col items-center relative"
      >
        <div
          className="flex flex-col w-[95%] p-[12px]"
        >
          <BreadCrumb
            variant={'SEARCH_DETAIL'}
            navigationCollection={breadCrumbItems}
          />
          <ProductSearch 
            filter={{
              ...filter,
              filtersArr: [],
              hitsPerPage: 4
            }}
            additionalData={apiData?.additionalData}
            onSearchTermChange={onSearchTermChange}
          />
        </div>
      </div>
    </Layout>
  )
}
export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
  return (
    <>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
    </>
  )
}

SearchPage.propTypes = {
  pageContext: PropTypes.shape({
    globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    componentProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    pageType: PropTypes.string,
    dataLayer: PropTypes.object,
    seoData: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      ogTitle: PropTypes.string,
      ogDescription: PropTypes.string,
      ogType: PropTypes.string,
      twitterTitle: PropTypes.string,
      twitterDescription: PropTypes.string,
      canonicalLink: PropTypes.string,
      metaTitle: PropTypes.string,
      featuredImage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    apiData: PropTypes.object
  }),
}

export default SearchPage
