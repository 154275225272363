export const defaultStyle = {
  container : "mx-auto",
  breadcrumbStyle : "text-xs md:text-lg tracking-widest mb-15",
  heading : "mb-20 text-4xl md:text-6xl leading-tight tracking-wider font-normal uppercase",
  breadcrumbItems : "flex mb-5 md:mb-10",
  breadcrumbItemsStyle : "text-sm md:text-lg no-underline hover:underline",
  breadcrumbDevider : "pr-1",
}

export const BREADCRUMB_ALL_PRODUCT = {
  container : "max-w-1170 w-full mx-auto lg:px-0 pt-10 pb-0 lg:py-15 py-10 lg:mt-15",
  breadcrumbItems:"flex lg:px-0 px-20 breadcrumbFont",
  breadcrumbItemsStyle : "text-base md:text-[16px] md:leading-[16px] lg:text-[18px] lg:leading-[18px] no-underline tracking-[2.77px]",
  breadcrumbDevider: "mx-5"    
}
export const BREADCRUMB_ALL_PRODUCT_MILKA_SKI = {
  container : "w-full breadcrumb_product_milka_ski lg:px-0 pt-10 pb-0 lg:py-15 py-10",
  breadcrumbItems:"max-w-1170 w-full mx-auto  flex lg:px-0 px-20",
  breadcrumbItemsStyle : "text-base md:text-[18px] md:leading-[18px] no-underline tracking-[2.77px] last:font-bold",
  breadcrumbDevider: "mx-5"    
}
export const PRODUCT_DETAIL = {
  container: 'pt-[10px] hidden md:flex',
  breadcrumbItems: 'block py-[15px] breadcrumb',
  breadcrumbItemsStyle: "text-[18px] leading-[18px] text-[#3b2774] tracking-[2.77px] uppercase [&>span]:mx-[9px] last:font-bold",
  breadcrumbItems: 'block py-[15px] breadcrumb pl-20 xl:pl-0',
  breadcrumbItemsStyle: "text-[18px] leading-[18px] text-[#3b2774] tracking-[2.77px] uppercase [&>span]:mx-[9px] ",
}
export const ARTICLE_BREADCRUMB = {
  container : "w-full bg-[#3c2c72] text-white pb-30 hidden md:inline-block",
  breadcrumbItems:"max-w-1170 w-full mx-auto flex lg:py-15 py-10 pl-20 xl:pl-0",
  breadcrumbItemsStyle : "text-base md:text-[18px] md:leading-[18px] no-underline tracking-[2.77px] last:font-bold",
  breadcrumbDevider: "mx-5"    
}
export const BREADCRUMB_LEFT_ALIGN = {
  container : "w-full mt-10 mb-30 hidden md:inline-block",
  breadcrumbItems:"relative max-w-1170 w-full mx-auto flex lg:py-15 py-10 pl-20 xl:pl-0 breadcrumn ",
  breadcrumbItemsStyle : "text-base md:text-[18px] md:leading-[18px] no-underline tracking-[2.77px] uppercase [&>span]:mx-[12px] ",
  breadcrumbDevider: "mx-5"    
}
export const SEARCH_DETAIL = {
  ...PRODUCT_DETAIL,
  container: "pt-[10px] hidden md:block w-full max-w-[1170px] mx-auto",
  breadcrumbItems: 'block py-[15px] flex breadcrumb pl-20 xl:pl-0',

}