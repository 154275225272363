export const GTMScript = `(function (w, d, s, l, i) {
  w[l] = w[l] || []
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : ""
  j.async = true
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
  f.parentNode.insertBefore(j, f)
})(window, document, "script", "dataLayer", "GTM-NLQ5FL5")`

export const gTagScriptConsent = ` 
window.dataLayer = window.dataLayer || [];
function gtag() {
 dataLayer.push(arguments);
}
gtag('consent', 'default', {
 ad_storage: "denied",
 analytics_storage: "denied",
 functionality_storage: "denied",
 personalization_storage: "denied",
 security_storage: "denied",
 ad_user_data: "denied",
 ad_personalization: "denied",
 'wait_for_update': 500
});
`;
