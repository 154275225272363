import * as React from "react"
import PropTypes from "prop-types"
import {
  convertToWebp,
  transformImage,
} from "../../../helpers/ImageHelper"
import { TAB } from "../../../constants/breakPoints.constant";

const CustomImage = props => {
  const {
    src,
    desktopScreenImage,
    mobileScreenImage,
    className,
    alt,
    additionalParams,
    onClick = () => null,
    width = null,
    height = null,
    eventClass = '',
    eventLabel,
    isLazy = false
  } = props

  if (!src && !desktopScreenImage?.url && !mobileScreenImage?.url) return null

  const classNameFinal = [className, eventClass].join(" ")

  // get Image url 
  const getUrl = (src,additionalParams,desktopScreenUrl,mobileScreenUrl) => {
    if(src){
      return(transformImage(src,additionalParams));
    }else if(mobileScreenUrl){
      return(transformImage(mobileScreenUrl,additionalParams));
    }else if(desktopScreenUrl){
      return(transformImage(desktopScreenUrl,additionalParams));
    }else{
      return ''
    }
  }

  // assign src, alt, w, h from either contentful Image object or direct params passed
  const imgSrc = getUrl(src,additionalParams,desktopScreenImage?.url,mobileScreenImage?.url);
  const imgAlt = alt || "";
  const imgW = width ||  mobileScreenImage?.width || desktopScreenImage?.width || "auto";
  const imgH = height || mobileScreenImage?.height || desktopScreenImage?.height || "auto";

  return (
    <picture>
      {desktopScreenImage?.url && <source media={`(min-width:${TAB}px)`} srcSet={convertToWebp(desktopScreenImage.url)} width={desktopScreenImage.width} height={desktopScreenImage.height}/>} 
      {mobileScreenImage?.url && <source media={`(max-width:${(TAB-1)}px)`} srcSet={convertToWebp(mobileScreenImage.url)} width={mobileScreenImage.width} height={mobileScreenImage.height}/>}
      <img
        role="presentation" // for accessibility
        src={convertToWebp(imgSrc)}
        loading={isLazy?'lazy':'eager'}
        alt={imgAlt}
        className={classNameFinal}
        onClick={onClick}
        width={imgW}
        height={imgH}
        data-action-detail={eventLabel}
      />
    </picture>
  )
}

export default React.memo(CustomImage);

CustomImage.propTypes = {
  src: PropTypes.string,
  desktopScreenImage : PropTypes.object,
  mobileScreenImage : PropTypes.object,
  className: PropTypes.string,
  alt: PropTypes.string,
  additionalParams: PropTypes.object,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  eventClass: PropTypes.string,
  eventLabel: PropTypes.string,
  isLazy : PropTypes.bool,
}