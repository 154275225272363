import React from "react"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"
import * as styles from "./BreadCrumb-tw-styles"
import useScreenSize from "../../hooks/useScreenSize"

const BreadCrumb = props => {
  // eslint-disable-next-line react/prop-types
  const { navigationCollection, variant } = props
  let style = styles[variant] || styles?.defaultStyle
  const navigationItems = navigationCollection?.items || []
  const totalItems = navigationItems.length - 1
  const screen = useScreenSize()
  if(screen && screen?.isMobile) {
    return null
  }
  return (
    <>
      <div className={style.container}>
        <div className={style.breadcrumbItems}>
          {navigationItems.map((item, i) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <span className={style.breadcrumbItemsStyle} key={`breadcrumbs_${i}`}>
                <Link
                  label = {item.link.title}
                  to={item.link.url}
                  url={item.link.url}
                  className = {i === navigationItems.length - 1 ? 'bold':''}
                />
                {
                  i < totalItems && (
                    <span className={style?.breadcrumbDevider}>
                      /
                    </span>
                  )
                }
              </span>
            )
          })}
        </div>
      </div>
    </>
  )
}

BreadCrumb.propTypes = {
  navigationCollection: PropTypes.object,
  variant: PropTypes.string,
}

export default BreadCrumb
